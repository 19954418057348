.DashboardContainer{
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;
}

.DashboardBackground{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.VideoBackground{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}

.DashboardContent{
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DashboardTitle{
    color: #fff;
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px;) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px;) {
        font-size: 32px;
    }
}

.DashboardTitleDescription{
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px;) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px;) {
        font-size: 32px;
    }
}

.DashboardTitleButtonWrap{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DashboardTitleButton{
    margin-top: 30px;
    background: #bd2c00;
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    margin-right: 20px;
}

.DashboardTitleButtonName{
    display: block;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 15px;
    background: #c9bb60;
    color: white;
    transform: translateY(-6px);
}

.DashboardTitleButton:active .DashboardTitleButtonName{
    transform: translateY(-2px);
}