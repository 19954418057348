* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.service,
.swagger-ui.html,
.login {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  color: #fff;
  font-size: 100px;
}

.swagger-ui.html {
  color: #fff;
  font-size: 100px;
}

.login {
  color: #fff;
  font-size: 100px;
}

.admin-panel{
  display: flex;
}